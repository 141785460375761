var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$store.state.authorized)?_c('CHeader',{attrs:{"fixed":"","with-subheader":"","light":""}},[_c('CNavbarBrand',{staticClass:"ml-2 mt-1"},[_c('CLink',{attrs:{"to":"/tenders"}},[_c('img',{staticClass:"mx-auto",attrs:{"src":"/android-icon-48x48.png","width":"40","height":"40","alt":"logo Tender Telecom"}})])],1),_c('CDropdown',{staticClass:"c-header-nav-items list-group d-block d-lg-none mr-auto",attrs:{"data-cy":"mobileHeader","inNav":"","placement":"bottom-end","add-menu-classes":"pt-0"},scopedSlots:_vm._u([{key:"toggler",fn:function(){return [_c('CToggler',{staticClass:"mt-3 ml-1 d-block d-lg-none d-xl-none",attrs:{"in-header":""}})]},proxy:true}],null,false,946747927)},[(_vm.can('provider_user'))?_vm._l((_vm.providerHeader),function(val,key){return _c('CDropdownItem',{class:[_vm.currentPage.includes(val) ? _vm.activeClass : '', 'px-3'],attrs:{"to":{ name: val }}},[_vm._v(_vm._s(key)+" ")])}):_vm._e(),(_vm.can('bank_user'))?[_vm._l((_vm.bankHeader),function(item){return [(
            item.roles.some(
              (role) =>
                _vm.$store.state.login_info.roles.hasOwnProperty(role) &&
                _vm.$store.state.login_info.roles[role]
            )
          )?_c('CDropdownItem',{class:[
            _vm.currentPage.includes(item.link) ? _vm.activeClass : '',
            'px-3',
          ],attrs:{"to":{ name: item.link }}},[_vm._v(_vm._s(item.name)+" ")]):_vm._e()]})]:_vm._e()],2),_c('CHeaderNav',{staticClass:"d-md-down-none d-sm-down-none mr-auto",attrs:{"data-cy":"browserHeader"}},[(_vm.can('provider_user'))?_vm._l((_vm.providerHeader),function(val,key){return _c('CHeaderNavItem',{key:val,class:[_vm.currentPage.includes(val) ? _vm.activeClass : '', 'px-2']},[_c('CHeaderNavLink',{attrs:{"to":{ name: val }}},[_vm._v(_vm._s(key))])],1)}):_vm._e(),(_vm.can('bank_user'))?[_vm._l((_vm.bankHeader),function(item){return [(
            item.roles.some(
              (role) =>
                _vm.$store.state.login_info.roles.hasOwnProperty(role) &&
                _vm.$store.state.login_info.roles[role]
            )
          )?_c('CHeaderNavItem',{class:[
            _vm.currentPage.includes(item.link) ? _vm.activeClass : '',
            'px-2',
          ]},[_c('CHeaderNavLink',{attrs:{"to":{ name: item.link }}},[_vm._v(_vm._s(item.name))])],1):_vm._e()]}),_c('CButton',{attrs:{"color":"success"},on:{"click":_vm.navigationV2}},[_vm._v("V2")])]:_vm._e()],2),_c('CHeaderNav',{staticClass:"mr-4"},[_c('TheHeaderDropdownAccnt')],1),_c('CModal',{attrs:{"color":"danger","title":"Сеанс завершено","size":"lg","addContentClasses":"srl","show":_vm.showModalUnauthorized},on:{"update:show":function($event){_vm.showModalUnauthorized=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('CCardBody',[_c('h3',[_vm._v("Для продовження роботи оновіть, будь ласка, сторінку")])])]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":_vm.clearSession}},[_vm._v("Оновити ")])]},proxy:true}],null,false,1499576917)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }