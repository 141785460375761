<template>
  <CHeader fixed with-subheader light v-if="this.$store.state.authorized">
    <CNavbarBrand class="ml-2 mt-1">
      <CLink to="/tenders"
        ><img
          class="mx-auto"
          src="/android-icon-48x48.png"
          width="40"
          height="40"
          alt="logo Tender Telecom"
      /></CLink>
    </CNavbarBrand>
    <CDropdown
      data-cy="mobileHeader"
      inNav
      class="c-header-nav-items list-group d-block d-lg-none mr-auto"
      placement="bottom-end"
      add-menu-classes="pt-0"
    >
      <template #toggler>
        <CToggler in-header class="mt-3 ml-1 d-block d-lg-none d-xl-none" />
      </template>
      <template v-if="can('provider_user')">
        <CDropdownItem
          v-for="(val, key) of providerHeader"
          :class="[currentPage.includes(val) ? activeClass : '', 'px-3']"
          :to="{ name: val }"
          >{{ key }}
        </CDropdownItem>
      </template>
      <template v-if="can('bank_user')">
        <template v-for="item in bankHeader">
          <CDropdownItem
            v-if="
              item.roles.some(
                (role) =>
                  $store.state.login_info.roles.hasOwnProperty(role) &&
                  $store.state.login_info.roles[role]
              )
            "
            :class="[
              currentPage.includes(item.link) ? activeClass : '',
              'px-3',
            ]"
            :to="{ name: item.link }"
            >{{ item.name }}
          </CDropdownItem>
        </template>
      </template>
    </CDropdown>
    <CHeaderNav
      class="d-md-down-none d-sm-down-none mr-auto"
      data-cy="browserHeader"
    >
      <template v-if="can('provider_user')">
        <CHeaderNavItem
          v-for="(val, key) of providerHeader"
          :key="val"
          :class="[currentPage.includes(val) ? activeClass : '', 'px-2']"
        >
          <CHeaderNavLink :to="{ name: val }">{{ key }}</CHeaderNavLink>
        </CHeaderNavItem>
      </template>
      <template v-if="can('bank_user')">
        <template v-for="item in bankHeader">
          <CHeaderNavItem
            v-if="
              item.roles.some(
                (role) =>
                  $store.state.login_info.roles.hasOwnProperty(role) &&
                  $store.state.login_info.roles[role]
              )
            "
            :class="[
              currentPage.includes(item.link) ? activeClass : '',
              'px-2',
            ]"
          >
            <CHeaderNavLink :to="{ name: item.link }">{{
              item.name
            }}</CHeaderNavLink>
          </CHeaderNavItem>
        </template>
        <CButton @click="navigationV2" color="success">V2</CButton>
      </template>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CModal
      color="danger"
      title="Сеанс завершено"
      size="lg"
      addContentClasses="srl"
      :show.sync="showModalUnauthorized"
    >
      <template #body-wrapper>
        <CCardBody>
          <h3>Для продовження роботи оновіть, будь ласка, сторінку</h3>
        </CCardBody>
      </template>
      <template #footer>
        <CButton color="danger" @click="clearSession">Оновити </CButton>
      </template>
    </CModal>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import { getPathByName, routerGoToName } from "@/utils/routerUtils";
import { can } from "@/rules/permissions";
import { removeLocalCookies } from "@/utils/cookiesUtils";

export default {
  name: "TheHeader",
  data() {
    return {
      activeClass: "active",
      showModalUnauthorized: false,
      providerHeader: {
        Тендери: "Tenders",
        "Персональний кабінет": "ProviderInfo",
        "Архів тендерів": "ProviderTenderArchive",
        "Зворотній зв'язок": "FeedBack",
        Довідка: "FAQ",
      },
      bankHeader: [
        {
          name: "Заявки",
          link: "Tenders",
          roles: ["aa", "ac", "mg", "vd", "ad", "zm", "za"],
        },
        { name: "Провайдери", link: "BankAdminPortal", roles: ["mg", "ad"] },
        {
          name: "Архів заявок",
          link: "BankHistoryOrders",
          roles: ["aa", "ac", "mg", "vd", "ad", "zm", "za"],
        },
        { name: "Архів Email", link: "BankHistoryEmails", roles: ["mg", "ad"] },
        {
          name: "Зворотній зв'язок",
          link: "FeedBack",
          roles: ["aa", "ac", "mg", "vd", "ad", "zm", "za"],
        },
        { name: "Дашборд", link: "BankDashboard", roles: ["ad"] },
        { name: "Файли", link: "BankFileManager", roles: ["ad"] },
        {
          name: "Довідка",
          link: "FAQ",
          roles: ["aa", "ac", "mg", "vd", "ad", "zm", "za"],
        },
      ],
    };
  },
  components: {
    TheHeaderDropdownAccnt,
  },
  computed: {
    currentPage() {
      return this.$route.name;
    },
  },
  methods: {
    can,
    getPathByName,
    routerGoToName,
    clearSession() {
      this.$store.commit("updateAuthorized", false);
      removeLocalCookies("tender_telecom_authorized");
      const location = window.location.pathname;
      this.routerGoToName("Login", "query", { next: location });
      // this.$router.push({path: `/login?next=${location}`})
    },
    navigationV2() {
      const link = new URL("/v2", window.location.origin);
      console.log(link.toString());
      window.open(link.toString(), "_blank");
    },
  },
  watch: {
    "$store.state.showModalUnauthorized": function () {
      if (this.$store.state.showModalUnauthorized) {
        this.showModalUnauthorized = true;
        return;
      } else {
        return (this.showModalUnauthorized = false);
      }
    },
  },
};
</script>
