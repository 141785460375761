<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink v-if="can('provider_user')">
        <div class="d-none d-sm-block pr-1">
          <p class="pt-2 pr-2 mb-0">{{ $store.state.login_info.last_name }}
            {{ $store.state.login_info.first_name }} </p>
          <span class="header-organization-style">{{ $store.state.login_info.org_name }}</span>
        </div>
        <div class="c-avatar">
          <span
              class="short-name-style pr-1">{{
              $store.state.login_info.last_name.charAt(0).toUpperCase()
            }}{{ $store.state.login_info.first_name.charAt(0).toUpperCase() }}</span>
        </div>
      </CHeaderNavLink>
      <CHeaderNavLink v-if="can('bank_user')">
        <p class="d-none d-sm-block pt-2 pr-2">{{ $store.state.login_info.full_name }}</p>
        <!--        <span class="d-none d-sm-block header-organization-style-admin">{{$store.state.login_info.login}}</span>-->
        <span class="d-none d-sm-block header-organization-style-admin">
            <template v-for="(has, role) in $store.state.login_info.roles">
                {{ longRolesBankUser(role) }} ({{ role }})
            </template>
        </span>
        <div class="c-avatar">
          <!--            <img class="c-avatar" :src="'https://dpd.privatbank.ua/readfoto2.php?ldap='+$store.state.login_info.login">-->
          <img class="c-avatar" :src="`${baseURL}/api/icon/photo/${$store.state.login_info.login}`">
        </div>
      </CHeaderNavLink>
    </template>

    <span v-if="can('ability_make_settings')">
      <CDropdownItem @click="toSettings">
        <CIcon name="cil-settings" class="mr-1"/>
        Налаштування
      </CDropdownItem>
    </span>

    <span v-if="can('view_edit_manager_it_rp')">
      <CDropdownItem @click="toViewManagerIT">
        <CIcon name="cil-people" class="mr-1"/>
        Керівники ІТ
      </CDropdownItem>
    </span>

    <span v-if="can('bank_user')">
      <CDropdownItem @click="toViewRules">
        <CIcon name="cil-shield-alt" class="mr-1"/>
        Правила доступу
      </CDropdownItem>
    </span>

    <CDropdownItem @click="logOut">
      <CIcon name="cil-lock-locked" class="mr-1"/>
      Вихід
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import axios from "axios";
import longRolesBankUser from "@/mixins/shortName"
import {routerGoToName} from "@/utils/routerUtils";
import {can} from "@/rules/permissions";
import {removeLocalCookies} from "@/utils/cookiesUtils";

export default {
  mixins: [longRolesBankUser],
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {}
  },
  methods: {
    can,
    logOut() {
      let self = this;
      axios.get('/api/account/logout')
          .then(function () {
            self.$store.commit('updateAuthorized', false);
            self.$store.commit('clearLoginInfo');
            removeLocalCookies('tender_telecom_authorized');
            routerGoToName('Login');
            // self.$router.push({path: `/login`})
          })
          .catch(function () {
          });
    },
    toSettings() {
      routerGoToName('BankSettings');
    },
    toViewRules() {
      routerGoToName('BankViewRules')
    },
    toViewManagerIT() {
      routerGoToName('BankEditManagerITRP')
    }
  },
  created() {
    this.baseURL = axios.defaults.baseURL;
  }
}
</script>
