<template>
  <CFooter>
    <div>
      <a href="https://pb.ua" target="_blank">TenderTelecom</a>
      <span class="ml-1">&copy; 2020-{{ getDate }}</span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">Powered by</span>
      <a href="https://pb.ua" target="_blank">PrivatBank</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    getDate() {
      return new Date().getFullYear().toString();
    }
  }
}
</script>
